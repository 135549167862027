<template>
  <div v-if="!emptyObject(currentIncentive.attributes.project_type)">
    <b-modal
      :id="`incentive-contact-${incentiveType}-${currentIncentive.id}`"
      :size="'lg'"
      @show="resetModal"
      @hide="onModalClose"
      centered
      class="egx-partner-color custom-modal app"
      hide-footer
      hide-header
      :ref="`incentive-contact-${incentiveType}-${currentIncentive.id}`"
    >
      <div class="m-4 text-center" v-if="isLoading">
        <font-awesome-icon
          :icon="['fa', 'circle-notch']"
          class="fa-spin m-auto spinner fa-4x"
        />
      </div>
      <template v-else>
        <div class="row m-1 incentives contact justify-content-center" :style="customCss">
          <div class="col-10">
            <h4
              class="popup-title"
              :class="currentSection.title.class"
              :style="currentSection.title.style"
            >
              {{ currentSection.title.text }}
            </h4>
            <div class="popup-sub-title">
              <p class="popup-sub-title" v-if="!eaAssignment">
                {{ currentSection.sub_title.contact.text }}
              </p>
              <p v-else>
                {{ currentSection.sub_title.contacted.text }}
              </p>
            </div>
          </div>

          <div class="col-2">
            <img
              src="../assets/images/icons/close.svg"
              class="btn-close"
              @click="$bvModal.hide(`incentive-contact-${incentiveType}-${currentIncentive.id}`)"
            />
          </div>

          <div class="col-12 mt-2">
            <div class="row d-flex justify-content-between m-1">
              <div class="detail-display-container col-12 col-md-4 p-3 mb-3">
                <div
                  class="sub-section-title2"
                  :class="currentSection.info_section.address_info.title.class"
                  :style="currentSection.info_section.address_info.title.style"
                >
                  {{ currentSection.info_section.address_info.title.text }}
                </div>
                <div class="detail-section pt-2">
              <span notranslate>
                {{ currentBuilding.address1 }}
              </span>
                  <span isolate>
                {{ currentBuilding.city }}
              </span>
                  <span isolate>
                {{ currentBuilding.region }}
              </span>
                  <span notranslate>
                {{ formatZip(currentBuilding.zip, currentBuilding.country) }}
              </span>
                </div>
              </div>
              <div class="detail-display-container col-12 col-md-4 p-3 mb-3">
                <div
                  class="sub-section-title2"
                  :class="currentSection.info_section.tech_info.title.class"
                  :style="currentSection.info_section.tech_info.title.style"
                >
                  {{ currentSection.info_section.tech_info.title.text }}
                </div>
                <div class="detail-section pt-2">{{ currentIncentive.attributes.title }}</div>
              </div>
              <div class="detail-display-container col-12 col-md-4 p-3 mb-3">
                <div
                  class="sub-section-title2"
                  :class="currentSection.info_section.total_info.title.class"
                  :style="currentSection.info_section.total_info.title.style"
                >
                  {{ currentSection.info_section.total_info.title.text }}
                </div>
                <div class="detail-section pt-2">
                  {{ shortDescription }}
                </div>
              </div>
            </div>
          </div>

          <div v-if="showButton">
            <div v-show="errorMsg" class="alert alert-danger col-12">
              <div class="msg-error text-danger">{{ errorMsg }}</div>
            </div>

            <div class="p-0 pr-sm-2 d-flex flex-column justify-content-center">
              <b-button
                class="mb-2 p-2 btn-egx-white w-100 justify-content-center"
                @click="$event => setApplication()"
              >
                Schedule your assessment
              </b-button>
              <div v-show="requireConsent" class="constent-link">
                <label for="consent-checkbox">
                  <input id="consent-checkbox" v-model="consent" type="checkbox" name="consent">
                  <span
                    class="pl-2"
                    :class="currentSection.consent.class"
                    :style="currentSection.consent.style"
                    v-html="currentSection.consent.text"
                  />
                </label>
              </div>
            </div>
          </div>
          <div
            class="row col-12 mt-3 mb-2 pr-0 align-items-start"
            v-if="!applied && (!schedulePlan.enabled || !showButton)"
          >
            <div v-show="errorMsg" class="alert alert-danger col-12">
              <div class="msg-error text-danger">{{ errorMsg }}</div>
            </div>

            <div
              class="col-12 col-sm-8 p-0 pr-sm-2 d-flex flex-column justify-content-center"
            >
              <div class="mb-2" v-if="partner.settings.manual_energy_advisor_assignment">
                <select class="w-100" v-model="energyAdvisorId">
                  <option :value="''">Smart Assign Energy Advisor</option>
                  <option v-for="(item, key) in energyAdvisors" :value="item.id" :key="key">
                    {{ item.name || item.attributes.name }}
                  </option>
                </select>
              </div>
              <div
                v-show="requireConsent"
                class="constent-link"
              >
                <label for="consent-checkbox">
                  <input id="consent-checkbox" v-model="consent" type="checkbox" name="consent">
                  <span
                    class="pl-2"
                    :class="currentSection.consent.class"
                    :style="currentSection.consent.style"
                    v-html="currentSection.consent.text"
                  />
                </label>
              </div>
            </div>

            <div
              @click="schedulePlan.enabled ? setApplication(true) : requestEnergyAdvisor()"
              class="col-12 col-sm-4 btn btn-incentive"
              :class="currentSection.contact_btn.class"
              :style="currentSection.contact_btn.style"
            >
              <span>{{ currentSection.contact_btn.text }}</span>
            </div>
          </div>
        </div>
        <div v-for="(project, projectIndex) in currentProjects" :key="project.id" class="m-4">
          <div v-if="schedulePlan.enabled">
            <hr v-show="projectIndex !== 0" class="mt-4" />
            <div class="required-section m-2 pb-1 col-12 pt-3">
              <div class="mb-3">
              <span
                class="d-block sub-section-title"
                :style="currentSection.document_section.title.style"
              >
                Application ID
              </span>
                <span class="text-black-50">
                {{ project.id }}
              </span>
              </div>
              <div class="mb-3 mt-3">
              <span
                class="d-block sub-section-title"
                :style="currentSection.document_section.title.style"
              >
                Application Status
              </span>
                <span class="text-black-50">
                {{ project.project_status.status }}
              </span>
              </div>
              <div class="mb-3 mt-3">
              <span
                class="d-block sub-section-title"
                :style="currentSection.document_section.title.style"
              >
                Application Requested At
              </span>
                <span class="d-block">{{ formatDate(project.created_at) }}</span>
              </div>
              <div v-for="childProject in project.child_projects" :key="childProject.id">
                <div class="mb-3" v-if="childProject.schedule_plan_assigment">
                <span
                  class="d-block sub-section-title"
                  :style="currentSection.document_section.title.style"
                >
                  Scheduled At
                </span>
                  <span>
                  {{
                      formatDate(
                        childProject.schedule_plan_assigment.schedule_plan_timeslot.start_at, true
                      )
                    }} to
                  {{
                      formatDate(
                        childProject.schedule_plan_assigment.schedule_plan_timeslot.finish_at, true
                      )
                    }}
                </span>
                </div>
                <div
                  class="mb-3"
                  v-if="!childProject.schedule_plan_assigment
                && childProject.project_status.user_scheduling_enabled"
                >
                  <b-button
                    class="mb-2 p-2 btn-egx-white w-100 justify-content-center"
                    @click="$event => redirectToScheduleSection(childProject.id)"
                  >
                    Go to Scheduling Page
                  </b-button>
                </div>
                <div
                  class="mb-3"
                  v-if="readOnlyDocumentList.length > 0
                  && documentForCustomer(childProject).length > 0"
                >
                <span
                  class="sub-section-title"
                  :class="currentSection.document_section.title.class"
                  :style="currentSection.document_section.title.style"
                >
                  {{ currentSection.document_section.title.text }}
                </span>
                  <div v-for="list in readOnlyDocumentList" :key="list.id">
                    <div v-for="(listItem, index) in list.document_list_item" :key="listItem.id">
                      <div
                        class="document-section m-3"
                        :class="documentSectionNotifyClass(
                        childProject, applied, hasDocumentUploadNotify, listItem.id
                      )"
                        v-if="documentForCustomer(childProject).includes(listItem.id)"
                      >
                        <div class="row p-2">
                          <p class="col-2 col-md-1 emoji-icon mb-0">&#x1F4C4;</p>
                          <div class="col-6 col-md-8 col-lg-9">
                            <p class="mb-0 sub-section-title">
                              {{ listItem.name }}
                            </p>
                            <p class="mb-0 description-small">{{ listItem.description }}</p>
                          </div>
                        </div>
                        <document
                          :class="`listItem-${index}`"
                          :documentableType="'ListItem'"
                          :documentableId="listItem.id"
                          :ref="`document-listItem-${listItem.id}`"
                          :applied="applied"
                          :documentSection="currentSection.document_section"
                          :currentProject="childProject"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <hr v-show="projectIndex !== 0" class="my-4" />
            <span class="text-black-50">
            {{ projectStatusCreatedAt(project) }}
          </span>
            <div v-for="childProject in project.child_projects" :key="childProject.id">
              <div
                class="required-section m-2 pb-1 col-12"
                v-if="readOnlyDocumentList.length > 0
                  && documentForCustomer(childProject).length > 0"
              >
                <p
                  class="m-3 sub-section-title pt-2"
                  :class="currentSection.document_section.title.class"
                  :style="currentSection.document_section.title.style"
                >
                  {{ currentSection.document_section.title.text }}
                </p>
                <div v-for="list in readOnlyDocumentList" :key="list.id">
                  <div v-for="(listItem, index) in list.document_list_item" :key="listItem.id">
                    <div
                      class="document-section m-3"
                      :class="documentSectionNotifyClass(
                      childProject, applied, hasDocumentUploadNotify, listItem.id
                    )"
                      v-if="documentForCustomer(childProject).includes(listItem.id)"
                    >
                      <div class="row p-2">
                        <p class="col-2 col-md-1 emoji-icon mb-0">&#x1F4C4;</p>
                        <div class="col-6 col-md-8 col-lg-9">
                          <p class="mb-0 sub-section-title">
                            {{ listItem.name }}
                          </p>
                          <p class="mb-0 description-small">{{ listItem.description }}</p>
                        </div>
                      </div>
                      <document
                        :class="`listItem-${index}`"
                        :documentableType="'ListItem'"
                        :documentableId="listItem.id"
                        :ref="`document-listItem-${listItem.id}`"
                        :applied="applied"
                        :documentSection="currentSection.document_section"
                        :currentProject="childProject"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import zipFormatter from '../shared/mixins/zip-formatter';
import Document from './Document.vue';
import Incentive from '../shared/mixins/incentive';
import emptyObjectMixin from '../shared/mixins/empty-object';

export default {
  name: 'IncentiveContact',

  props: {
    currentIncentive: Object,
    shortDescription: String,
    hasDocumentUploadNotify: Boolean,
    applied: Boolean,
    incentiveType: String,
    currentProjects: Array,
  },

  mixins: [zipFormatter, Incentive, emptyObjectMixin],

  components: {
    Document,
  },

  data() {
    return {
      errorMsg: null,
      energyAdvisorId: '',
      consent: false,
      clickDisabled: null,
      schedulePlansLoaded: false,
      timeslotsLoaded: false,
      updatedIncentive: null,
      isLoading: true,
    };
  },

  created() {
    this.$store.dispatch('schedulePlans/get').then(() => {
      this.schedulePlansLoaded = true;
    });
    this.$store.dispatch('schedulePlanTimeslots/get').then(() => {
      this.timeslotsLoaded = true;
    });
  },

  mounted() {
    if (this.currentIncentive.attributes.require_contractor === true) {
      const projectTypeId = this.currentIncentive.attributes.project_type.id;

      this.$store.dispatch('energyAdvisorAssignments/get', projectTypeId);
    }

    this.isLoading = this.useOffer;
  },

  computed: {
    currentBuilding() {
      return this.$store.state.buildings.currentBuilding;
    },

    partner() {
      return this.$store.state.partners.currentPartner;
    },

    selectedIncentive() {
      return this.updatedIncentive || this.currentIncentive;
    },

    useOffer() {
      return this.partner.settings.energyxpert_admin_enabled;
    },

    availableEa() {
      return this.$store.state.energyAdvisorAssignments.availableEa;
    },

    requireConsent() {
      return this.partner.settings.user_consent_mandatory;
    },

    currentSection() {
      return this.partner.settings.sections.incentive_ea_assignment_popup;
    },

    energyAdvisors() {
      const ea = this.partner.settings.energyxpert_admin_enabled ? this.availableEa
        : this.currentBuilding.available_energy_advisors;

      if (this.partner.settings.enable_energy_advisor_preference) {
        const energyAdvisorIds = this.selectedIncentive.attributes.energy_advisor_ids;

        return ea.filter(
          (obj) => energyAdvisorIds.includes(obj.id),
        );
      }

      return ea;
    },

    projectTypeId() {
      return this.selectedIncentive.attributes.project_type.id;
    },

    eaAssignment() {
      if (this.incentiveType === 'applied') return this.currentBuilding.ea_assignment[this.projectTypeId];

      if (this.selectedIncentive.attributes.quantity_limitation_active) {
        return !this.$store.getters['offers/checkAvailableQuantity'](this.selectedIncentive);
      }

      return this.currentBuilding.ea_assignment[this.projectTypeId];
    },

    customCss() {
      return this.$store.state.partners.currentPartner.settings.sections.custom_css;
    },

    readOnlyDocumentList() {
      return this.selectedIncentive.attributes.read_only_document_list;
    },
    schedulePlan() {
      return this.selectedIncentive.attributes.schedule_plan;
    },
    showButton() {
      if (
        this.selectedIncentive.attributes.init_project_status.user_scheduling_enabled
        && this.schedulePlan.enabled
        && this.schedulePlan.types.includes('assessment')
        && this.schedulePlan.user.enabled
        && this.incentiveType !== 'applied'
      ) {
        return this.$store.state.schedulePlans.schedulePlans.length > 0;
      }
      return false;
    },

    // showConsent() {
    //   if (this.schedulePlan.enabled) {
    //     const childProjects = this.currentProjects
    //       .flatMap((project) => project.child_projects || []);

    //     const hasAssignment = childProjects.some((project) => !project.schedule_plan_assignment);
    //     if (hasAssignment) {
    //       const cancelled = childProjects.some((project) => (
    //         project.project_status.user_cancellation_enabled
    //       ));
    //       return !cancelled;
    //     }

    //     return hasAssignment;
    //   }
    //   return false;
    // },
  },

  methods: {
    resetModal() {
      this.errorMsg = null;
      if (this.isLoading) {
        this.$store.dispatch('offers/get', { id: this.currentIncentive.id })
          .then((incentive) => {
            this.updatedIncentive = incentive;
            this.isLoading = false;
          });
      }
    },

    onModalClose() {
      if (this.hasDocumentUploadNotify) {
        this.currentProjects.forEach(async (project) => {
          if (project.document_upload_notify_list !== null
            || project.document_upload_notify_list !== []) {
            await this.resetAdminUploadDocumentNotify(project.id);
          }
        });
      }
    },

    requestEnergyAdvisor() {
      if (this.clickDisabled) {
        return;
      }

      // Disable Click
      this.clickDisabled = true;

      // Re-enable after 3 seconds
      this.timeout = setTimeout(() => {
        this.clickDisabled = false;
      }, 3000);

      const self = this;
      const params = {
        subsidy_id: this.currentIncentive.id,
        energy_advisor_id: this.energyAdvisorId,
      };

      if (!this.consent && this.requireConsent) {
        this.errorMsg = 'The consent field is required';
      } else {
        this.errorMsg = null;

        this.$store.dispatch('energyAdvisorAssignments/create', params)
          .then(() => {
            this.$store.dispatch('buildings/get');
            this.$store.dispatch('buildings/updateEaAssignment', this.projectTypeId);
            this.$notify({
              group: 'notice',
              text: 'Successful Assignment',
            });
            this.$refs[`incentive-contact-${this.incentiveType}-${this.currentIncentive.id}`]
              .hide();
          })
          .catch((error) => {
            if (error.response && Object.keys(error.response.data)[0] === 'data') {
              self.errorMsg = error.response.data.data.attributes;
            } else if (error.response && Object.keys(error.response.data)[0] === 'errors') {
              self.errorMsg = error.response.data.errors;
            }

            this.$notify({
              group: 'notice',
              text: self.errorMsg,
            });
          });
      }
    },

    documentForCustomer(project) {
      if (project === undefined) {
        return [];
      }

      return project.admin_document_upload;
    },

    projectStatusCreatedAt(project) {
      return `${project.id} - ${project.project_status.status} (${this.formatDate(project.created_at)})`;
    },

    formatDate(dateString, isScheduled) {
      if (isScheduled) {
        return new Date(dateString).toLocaleString('en-US', {
          hour12: true, hour: 'numeric', minute: 'numeric', month: 'numeric', day: 'numeric', year: 'numeric',
        }).split('/').join('-');
      }
      return new Date(dateString).toLocaleDateString('en-US').split('/').join('-');
    },

    setApplication(managerSchedule) {
      if (this.clickDisabled) {
        return;
      }

      // Disable Click
      this.clickDisabled = true;

      // Re-enable after 3 seconds
      this.timeout = setTimeout(() => {
        this.clickDisabled = false;
      }, 3000);

      const payload = {
        id: this.currentIncentive.id,
        user_schedule: true,
        manager_schedule: managerSchedule,
        useOffer: this.useOffer,
      };
      const self = this;

      if (!this.consent && this.requireConsent) {
        this.errorMsg = 'The consent field is required';
      } else {
        this.$store.dispatch('buildings/setProject', payload)
          .then((response) => {
            this.$notify({
              group: 'notice',
              text: 'Application submitted.',
            });
            this.$store.dispatch('buildings/get');
            this.$store.dispatch('buildings/getAll');
            this.consent = false;
            this.$refs[`incentive-contact-${this.incentiveType}-${this.currentIncentive.id}`]
              .hide();
            if (!managerSchedule) this.$router.push(`/user-schedule-plans/${response}`);
          })
          .catch((error) => {
            if (error.response && Object.keys(error.response.data)[0] === 'data') {
              self.errorMsg = error.response.data.data.attributes;
            } else if (error.response && Object.keys(error.response.data)[0] === 'errors') {
              self.errorMsg = error.response.data.errors;
            } else {
              self.errorMsg = 'Unexpected error when trying to create the project';
            }

            this.$notify({
              group: 'notice',
              text: self.errorMsg,
            });
          });
      }
    },
    redirectToScheduleSection(id) {
      this.$router.push(`/user-schedule-plans/${id}`);
    },
  },

  beforeDestroy() {
    // clear the timeout before the component is destroyed
    clearTimeout(this.timeout);
  },
};
</script>

<style lang="scss" scoped>
@import '../assets/stylesheets/pages/incentives';
</style>
